<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addEvent'})">
      اضافة قسم
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="4" class="mb-3">
            <input-form placeholder="العنوان" label="العنوان" v-model="categoryData.title" name="title" validate="required"></input-form>
          </b-col>
<!--          <b-col md="9" class="mb-3">-->
<!--&lt;!&ndash;            :disabled="routeName === 'showProduct'"&ndash;&gt;-->
<!--            <textarea-form placeholder="الوصف" v-model="newsData.excerpt" label="وصف مختصر" name="details" validate="required"></textarea-form>-->
<!--          </b-col>-->
      </b-row>
        <b-row>
          <b-col md="9" class="d-flex justify-content-center align-items-center">
            <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة</b-button>
            <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
          </b-col>
        </b-row>
<!--                <div class="d-flex align-items-center justify-content-center ">-->
<!--                  <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.events') }}</b-button>-->
<!--                  <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>-->
<!--                </div>-->
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import newsServices from '../services/news'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      loadingSubmit: false,
      // loadingLogo: 0,
      categoryData: {
        title: ''
      }
    }
  },
  methods: {
    getCategory () {
      newsServices.getCategory(this.$route.params.id).then(res => {
        this.categoryData = res.data
      })
    },
    onSubmit () {
      if (this.$route.name === 'editCategory') {
        this.editCategory()
      } else {
        this.loadingSubmit = true
        newsServices.addCategory(this.categoryData).then(res => {
          core.showSnackbar('success', 'تم اضافة ')
          this.loadingSubmit = false
          this.$router.push({ name: 'categories' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    },
    editCategory () {
      newsServices.editCategory(this.$route.params.id, this.categoryData).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'categories' })
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getCategory()
    }
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
